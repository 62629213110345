import { FunctionComponent, ReactNode, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { DependencyAction, DependencyProps } from '../../core/IDependency';
import FormularElement from '../../core/formBuilder/formularElement';

interface ConditionalFieldProps {
  children: ReactNode;
  fieldName: string;
  rulesConfig?: DependencyProps[];
  elements: FormularElement[] | undefined;
}

export const ConditionalField: FunctionComponent<ConditionalFieldProps> = ({
  children,
  fieldName,
  rulesConfig,
  elements,
}) => {
  const { values, initialValues } = useFormState();
  const { mutators } = useForm();

  if (!rulesConfig || rulesConfig.length < 1) return <>{children}</>;
  let config = rulesConfig[0];

  const handleRule = (superiorValue: string) =>
    isConditionTrue(config.condition, superiorValue)
      ? doAction(config.action, superiorValue)
      : doAction(config.actionNegative, superiorValue);

  function doAction(action: DependencyAction, superiorValue: any) {
    switch (action.effect) {
      case 'show':
        if (values[fieldName] === undefined && initialValues[fieldName])
          mutators.setValue(fieldName, initialValues[fieldName], values);
        else if (elements?.length) {
          elements
            .filter(
              (el) =>
                el.fieldName &&
                initialValues[el.fieldName] &&
                values[el?.fieldName] === undefined &&
                (initialValues[el.fieldName] ||
                  el.refComponent === 'components/@material/timeComponent')
            )
            .map((el) => el.fieldName)
            .forEach((fieldNameToSetInitValue) => {
              mutators.setValue(
                fieldNameToSetInitValue,
                initialValues[fieldNameToSetInitValue!],
                values
              );
            });
        }

        return children;
      case 'hide':
        if (values[fieldName]) mutators.setValue(fieldName, undefined, values);
        else if (elements?.length) {
          elements
            .filter((el) => el.fieldName && values[el?.fieldName])
            .map((el) => el.fieldName)
            .forEach((fieldNameToCleanUp) =>
              mutators.setValue(fieldNameToCleanUp, undefined, values)
            );
        }
        return null;
    }
  }

  return (
    <Field name={config.superiorName} subscription={{ value: true }}>
      {({ input: { value } }) => handleRule(value)}
    </Field>
  );
};

function isConditionTrue(
  condition: any,
  currentValue: string | boolean | number
): boolean {
  let threshold = condition.threshold;
  switch (condition.operator) {
    case '=':
      return currentValue === threshold;
    case '!=':
      return currentValue !== threshold;
    case 'Contains':
      return currentValue.toString().includes(threshold);
    case 'ContainsNot':
      return !currentValue.toString().includes(threshold);
    case '>':
      return isNaN(Number(currentValue))
        ? false
        : Number(currentValue) > Number(threshold);
    case '>=':
      return isNaN(Number(currentValue))
        ? false
        : Number(currentValue) >= Number(threshold);
    case '<':
      return isNaN(Number(currentValue))
        ? false
        : Number(currentValue) < Number(threshold);
    case '<=':
      return isNaN(Number(currentValue))
        ? false
        : Number(currentValue) <= Number(threshold);
    case 'True':
      return Boolean(currentValue);
    case 'False':
      return !Boolean(currentValue);
    case 'Checked':
      return (
        currentValue === true ||
        currentValue.toString().toLowerCase() === 'checked'
      );
    case 'Unchecked':
      return (
        currentValue === false ||
        currentValue.toString().toLowerCase() === 'unchecked'
      );
  }
  return false;
}

export default ConditionalField;
