import { PAYBACK_SERVICE_URL } from '../asb_modules/payback/PBApiConstants';
import { constants } from '../constants';

const api = {
  Public: {
    getIban,
    getConfig,
    getConfigByVersion,
    sendSessionStatus,
    getActiveConfigByProjectName,
  },
};

let headers = new Headers();
headers.append('content-type', 'application/json');

function getIban(project, iban) {
  return fetch(`${PAYBACK_SERVICE_URL.V1.getIBANpath(project)}`, {
    body: '{ "iban": "' + iban + '"}',
    method: 'POST',
    headers: headers,
  });
}

/**
 * @deprecated
 */
async function getConfig(project) {
  console.warn('Called deprecated function getConfig in index.js');
  const backendPath = project
    ? `${constants.BACKEND_URL}/config/releasedConfig?project=` + project
    : `${constants.BACKEND_URL}/config/releasedConfig`;
  return fetch(backendPath, {
    method: 'GET',
    headers: headers,
  });
}

async function getActiveConfigByProjectName(projectname) {
  return fetch(`${constants.BACKEND_URL}/v1/project/${projectname}/active`, {
    method: 'GET',
    headers: headers,
  }).then((res) => res.json());
}

async function getConfigByVersion(projectname, version) {
  return fetch(
    `${constants.BACKEND_URL}/v1/project/${projectname}/active/version/${version}`,
    {
      method: 'GET',
      headers: headers,
    }
  ).then((res) => res.json());
}

function sendSessionStatus(status, sessionId) {
  return fetch(`${constants.BACKEND_URL}/config/session`, {
    body: '{ "status": "' + status + '", "SessionId": "' + sessionId + '"}',
    method: 'POST',
    headers: headers,
  });
}

export function formDataHeaders() {
  return {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization:
        window._env_.REACT_APP_BACKEND_USERNAME +
        ':' +
        window._env_.REACT_APP_BACKEND_PASSWORD,
    },
  };
}

export default api;
