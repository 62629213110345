import FormularElement from '../formBuilder/formularElement';

/**
 * Checks if the legacy double column layout should be enabled for a provided Payback config
 * @param config The application process configuration
 * @returns false if the provided config has at least one page configured with two columns
 */
export const enableLegacyTwoColumnLayout = (config: any): boolean =>
  !_useNewTwoColumnLayout(config.form.elements, false);

const _useNewTwoColumnLayout = (
  elements: FormularElement[],
  isNew: boolean
): boolean => {
  if (isNew) return isNew;
  elements.forEach((element: FormularElement) => {
    if (element.elements)
      isNew = _useNewTwoColumnLayout(element.elements, isNew);
    if (
      typeof element.pageLayout?.columns?.length === 'number' &&
      element.pageLayout?.columns?.length > 1
    )
      isNew = true;
  });
  return isNew;
};
