export const AMEXKYCVALIDATION = {
  if: {
    properties: {
      Berufstatus: {
        enum: [
          'Angestellte:r oder Beamter/Beamtin in Vollzeit|FT',
          'Angestellte:r oder Beamter/Beamtin in Teilzeit|PT',
          'Geschäftsinhaber:in|BO',
          'Selbstständig|SE',
        ],
      },
    },
  },
  then: {
    required: ['Tätigkeit', 'Branche'],
  },
};

export const VISA_VALIDATION = {
  if: {
    properties: {
      SteuerAnsaessigkeit: {
        enum: ['Nein'],
      },
    },
  },
  then: {
    required: ['SteuerlandNichtD1', 'SteueridentifikationsnummerNichtD1'],
  },
};

export const VISA_SteuerlandNichtD1_ERRORS = {
  SteuerlandNichtD1: {
    required: 'Bitte wähle ein Land aus der Liste aus.',
  },
  SteueridentifikationsnummerNichtD1: {
    required: 'Steueridentifikationsnummer ist ein Pflichtfeld.',
  },
};

export const AMEXKYCERRORS = {
  Tätigkeit: {
    required: 'Bitte wähle eine Position aus der Liste aus.',
  },
  Branche: { required: 'Branche ist ein Pflichtfeld.' },
};
